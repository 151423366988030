import axios from 'axios';
import authHeader from './auth-header';
import constants from '../constants';
import { parseError } from '@/utils/utils'

class CustomerService {

    async getSimilarCustomers(customerId) {
      return new Promise(( resolve, reject ) => {
        axios.get(constants.API_URL + 'customer/' + customerId + '/similarCustomers', { headers: authHeader() }).then((response) => {
          console.log('getSimilarCustomers', response.data)
          resolve(response.data);
        }).catch((err) => {
          reject(err)
        })
      })
    }

    async getReportOtherCustomerSimilarCustomers(projectId) {
      return new Promise(( resolve, reject ) => {
        axios.get(constants.API_URL + 'customer/' + projectId + '/reportSimilarCustomers', { headers: authHeader() }).then((response) => {
          console.log('getReportOtherCustomerSimilarCustomers', response.data)
          resolve(response.data);
        }).catch((err) => {
          reject(err)
        })
      })
    }
    
    async getContacts(customerId) {
      return new Promise(( resolve, reject ) => {
        axios.get(constants.API_URL + 'customer/' + customerId + '/contacts', { headers: authHeader() }).then((response) => {
          console.log('getContacts', response.data)
          resolve(response.data);
        }).catch((err) => {
          reject(err)
        })
      })
    }

    async saveContactChanges(customerId, contactId, changes) {
      try {
        let response = await axios.post(
          constants.API_URL + 'customer/' + customerId + '/contact',
          { contactId: contactId, changes: changes },
          { headers: authHeader() }
        );
        return response;
      } catch (error) {
        console.log(error);
        return parseError(error);
      }
    }

    deleteContact(customerId, contactId) {
      console.log('deleteContact', customerId, contactId)
      return new Promise((resolve, reject) => {
        try {
          axios
            .delete(
              constants.API_URL + 'customer/' + customerId + '/contact/' + contactId,
              { headers: authHeader() }
            )
            .then((response) => {
              resolve(response);
            })
            .catch((err) => {
              reject(err);
            });
        } catch (error) {
          reject(error);
        }
      });
    }
}

export default new CustomerService();