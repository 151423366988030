<template>
  <v-card>
    <v-card-title>
      <a v-if="latitude && longitude" :href="'https://www.google.com/maps/dir/?api=1&destination=' + latitude + ',' + longitude"  target="_blank" class="directionLink mr-4">{{ $t("report.get_directions") }}</a>
        <v-text-field
          style="max-width: 500px;"
          :label="$t('report.searchAddress')"
          id="mapSearchAutocomplete"
        ></v-text-field>
      <div id="markerAddress" class="ml-0 ml-sm-4"></div>
      <v-icon style="position:absolute;top:15px;right:15px;" @click="close()">mdi-close</v-icon>
    </v-card-title>
    <v-card-text>
      <div id="map" style="height:75vh;width:100%;"></div>
    </v-card-text>
  </v-card>
</template>

<script>
import { getLongFormattedAddress } from "@/store/utility"
import { getAddressFromGooglePlace, getAddressFromLngLat } from "@/utils/utils"
import axios from "axios";

/*global google*/
export default {
  data: () => ({
    map: null,
    mapAutoComplete: null,
    markerLatLng: null,
    latitude: null,
    longitude: null
  }),
  props: {
    lat: {
      type: Number,
      default: null
    },
    lng: {
      type: Number,
      default: null
    },
    countries: {
      type: Array,
      required: true
    },
    fallbackAddress: {
      type: Object,
      default: null
    },
    addressCurrentlySet: { //Used to not replace address when the marker is moved
      type: Boolean,
      default: false
    },
    close: {
      type: Function,
      required: true
    },
    latLngChanged: {
      type: Function,
      required: true
    },
    addressChanged: {
      type: Function,
      required: true
    },
    askConfirmApplyGPS: {
      type: Function,
      required: true
    }
  },
  mounted() {
    this.longitude = this.lng;
    this.latitude = this.lat;
    if (!this.map) {
      setTimeout(() => { 
        this.initMap();
      }, 250);
    }
    else {
      this.updateMap();
    }
    this.initMapSearchField();
  },
  methods: {
    // Initialize and add the map
    async initMap() { // eslint-disable-line

      // On affiche la position du rapport si disponible
      if (this.latitude != null && this.longitude != null) {
        let point = { lat: this.latitude, lng: this.longitude };
        this.initMapWithPoint(point);
        return;
      }

      // Sinon on affiche le marker selon notre position GPS
      if (navigator.geolocation) {

        // Map will be initialized on position retrieval
        var state = this;
        navigator.geolocation.getCurrentPosition(function(pos) {
          state.initMapWithPoint({ lat: pos.coords.latitude, lng: pos.coords.longitude });
        },
        function(error) {
          console.log('error', error)
          state.continueInitMap();
        });
        return;
      }

      this.continueInitMap();
    },
    async continueInitMap() {

      // Sinon fallback à l'adresse du dealer
      if (this.fallbackAddress) {
        // 1. Format l'adresse du concessionnaire
        let formattedAddress = getLongFormattedAddress(this.countries, this.fallbackAddress);

        // 2. Axios google api pour aller chercher lat/lng
        let encodedFormattedAddress = encodeURIComponent(formattedAddress);
        let url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + encodedFormattedAddress + '&key=' + process.env.VUE_APP_GOOGLE_API_KEY;
        let response = await axios.get(url);
        if (response.data.results && response.data.results.length > 0) {
            let point = response.data.results[0].geometry.location;
            this.initMapWithPoint(point);
            return;
        }
      }

      // Dernier recours fallback à la maison mère de TP
      let point = { lat: 46.11118448049068, lng: -71.2913423841532 };
      this.initMapWithPoint(point);
    },
    setSearchFieldBounds() {
      if (navigator.geolocation) {
        let self = this;
        navigator.geolocation.getCurrentPosition(function(position) {
          var geolocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          var circle = new google.maps.Circle({
            center: geolocation,
            radius: position.coords.accuracy
          });

          self.mapAutoComplete.setBounds(circle.getBounds());
        });
      }
    },
    async initMapSearchField() {
      let el = document.getElementById('mapSearchAutocomplete');
      let elAddr = document.getElementById('markerAddress');
      while (el == null || elAddr == null) {
        await new Promise(r => setTimeout(r, 50));
        if (el == null) el = document.getElementById('mapSearchAutocomplete');
        if (elAddr == null) el = document.getElementById('markerAddress');
      }
      setTimeout(() => { el.placeholder = ""; el.value = ""; elAddr.innerHTML = '';}, 50);
      if (this.mapAutoComplete == null) {
        let self = this;
        this.mapAutoComplete = new google.maps.places.Autocomplete(el);
        this.mapAutoComplete.addListener('place_changed', async () => {
          self.inputAddr = el.value;
          let addr = await getAddressFromGooglePlace(self.mapAutoComplete.getPlace());
          self.updateMap({
            immediate: true,
            addressIsComplete: self.mapAutoComplete.getPlace().address_components.filter((item) => item.types.filter(t => t == 'street_number').length > 0).length > 0,
            address: addr.data
          });

          self.setSearchFieldBounds();
        });
        this.setSearchFieldBounds();
      }
    },
    handleDragEndEvent(event) {
      this.markerLatLng = event.latLng;
      this.changeGpsLocation();
    },
    async initMapWithPoint(point) {
      let mapElement = document.getElementById("map");
      while (mapElement == null) {
        await new Promise(r => setTimeout(r, 50));
        mapElement = document.getElementById("map");
      }
      this.map = new google.maps.Map(mapElement, {
        zoom: 10,
        center: point,
        gestureHandling: 'greedy',
        draggable: true
      });

      // On affiche un marqueur que si une adresse de site valide
      if (this.latitude != null && this.longitude != null) {
          this.marker = new google.maps.Marker({
            position: point,
            map: this.map,
            draggable: true
          });
          this.marker.addListener('dragend', this.handleDragEndEvent);
      }

      let scope = this;
      google.maps.event.addListener(this.map, 'click', async function(event) {
        if (await scope.askConfirmApplyGPS()) {
          scope.markerLatLng = event.latLng;
          scope.changeGpsLocation();
        }
      });
    },

    async convertAddrToCoordinates(addr) {
      let encodedFormattedAddress = encodeURIComponent(addr);
      let url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + encodedFormattedAddress + '&key=' + process.env.VUE_APP_GOOGLE_API_KEY;
      let response = await axios.get(url);
      if (response.data.results && response.data.results.length > 0) return response.data.results[0].geometry.location;
      return null;
    },

    async updateMap(params) {
      if (params == null) params = {};
      if (this.inputAddr != null && this.inputAddr.trim().length > 0) {
        if (params.immediate === true) {
          clearTimeout(this.centerMapTimer);
          this.centerMapTimer = null;
        }
        if (this.centerMapTimer == null) {
          let self = this;
          this.centerMapTimer = setTimeout(async () => {
            self.centerMapTimer = null;
            let found = await self.convertAddrToCoordinates(self.inputAddr);
            if (found != null) {
              self.map.setCenter(found);
              self.map.setZoom(params.addressIsComplete === true ? 20 : 13);
              params.doNotAlterMap = true;
              params.point = found;
            }
          }, params.immediate === true ? 0 : 1500);
        }
        return;
      }

      let point = params.point || { lat: this.latitude, lng: this.longitude };

      if (this.marker != null) {
        this.marker.setMap(null);
      }

      if (point.lat != null && point.lng != null) {
        this.marker = new google.maps.Marker({
            position: point,
            map: this.map,
            draggable: true
        });
        this.marker.addListener('dragend', this.handleDragEndEvent);
        if (params.doNotAlterMap !== true) this.map.setCenter(point);
      }
      if (params.doNotAlterMap !== true) this.map.setZoom(10);

      if (params.addressIsComplete) {
        if (!this.addressCurrentlySet) {
          this.addressChanged(params.address);
          let el = document.getElementById('markerAddress');
          if (el != null) el.innerHTML = '';
        }
        if (!this.longitude && !this.latitude) {
          this.longitude = Math.round(point.lng * 10000) / 10000;
          this.latitude = Math.round(point.lat * 10000) / 10000;
          this.latLngChanged({latitude: this.latitude, longitude: this.longitude})
        }
      }
    },
    changeGpsLocation() {
      if (this.marker != null) {
        this.marker.setMap(null);
      }

      this.marker = new google.maps.Marker({
          position: this.markerLatLng, 
          map: this.map,
          draggable: true
      });
      this.marker.addListener('dragend', this.handleDragEndEvent);

      let latitude = this.markerLatLng.lat();
      let longitude = this.markerLatLng.lng();

      // Arrondir à 4 décimales
      this.latitude = Math.round(latitude * 10000) / 10000;
      this.longitude = Math.round(longitude * 10000) / 10000;

      // Sauvegarder la nouvelle longitude et latitude
      this.latLngChanged({latitude: this.latitude, longitude: this.longitude});

      setTimeout(async () => {
        let response = await getAddressFromLngLat({lng: longitude, lat: latitude});
        if (response != null && response.data != null) {
          let el = document.getElementById('markerAddress');
          if (response.data.complete === true) {
            el.innerHTML = `${this.$t('report.useClosestAddress')}: <br/><a id="markerAddrLink" href="#">${response.data.shortFormattedAddress}</a>`;
            let link = document.getElementById('markerAddrLink');
            while (link == null) {
              await new Promise((r) => setTimeout(r, 50));
              link = document.getElementById('markerAddrLink');
            }
            link.addEventListener('click', async ev => {
              ev.preventDefault();
              this.addressChanged(response.data);
              el.innerHTML = this.$t('report.closestAddressUsed');
            });
          }
          else {
            el.innerHTML = this.$t('report.closestAddressInvalid');
          }
        }
      }, 0);
    },
  }
}
</script>

<style>
#markerAddress {
  font-size: 0.75em;
  margin-left: 20px;
}

.directionLink {
  font-size: 0.7em;
}
</style>
