
import i18n from "../i18n";
import { AVAILABLE_COUNTRIES } from '../enums';

//null or empty
function noe(item) {
  if (item == null) return true;
  if (Array.isArray(item) && item.length < 1) return true;
  else if (typeof item === 'string' && item.trim != null && item.trim() == '') return true;
  else if (typeof item === 'object' && Object.keys(item).length < 1) return true;
  return false;
}

function getLongFormattedAddress(countries, address) {
  if (noe(address)) return '';
  let country = address.countryId ? countries.find(({ id }) => id === address.countryId) : null;
  
  let formattedAddress = [];

  if (!noe(address.poBox)) formattedAddress.push(i18n.t("addressField.poBox") + ' ' + address.poBox);

  if (country && (country.id == AVAILABLE_COUNTRIES.FRANCE || country.id == AVAILABLE_COUNTRIES.BELGIUM || country.id == AVAILABLE_COUNTRIES.CANADA)) {
    if (!noe(address.address2)) formattedAddress.push(address.address2);
    if (!noe(address.address1)) formattedAddress.push(address.address1);
  }
  else {
    if (!noe(address.address1)) formattedAddress.push(address.address1);
    if (!noe(address.address2)) formattedAddress.push(address.address2);
  }

  if (country && (country.id == AVAILABLE_COUNTRIES.FRANCE || country.id == AVAILABLE_COUNTRIES.BELGIUM)) {
    if (!noe(address.postalCode)) formattedAddress.push(address.postalCode);
    if (!noe(address.city)) formattedAddress.push(address.city);
  }
  else {
    if (!noe(address.city)) formattedAddress.push(address.city);

    let province;
    if (country && address.stateId) province = country.states.find(({ id }) => id === address.stateId);
    if (!noe(province)) {
      if (country && country.id == AVAILABLE_COUNTRIES.UNITED_STATES) {
        if (!noe(province.code)) formattedAddress.push(province.code);
      }
      else if (!noe(province.name)) formattedAddress.push(province.name);
    }
    if (!noe(address.postalCode))formattedAddress.push(address.postalCode);
  }
  if (!noe(country) && !noe(country.name)) formattedAddress.push(country.name);
  
  return formattedAddress.join(', ');
}

function updateCustomerFormattedName(c) {
  if (!c) {
    return;
  }
  if (c.isOther) {
    c.formattedName = i18n.t("reportInfo.other");
    return;
  }

    c.formattedName = "";
    c.formattedName += c.company ? (c.formattedName ? ". " : "") + c.company : "";
    c.formattedName += c.firstname || c.lastname ? (c.formattedName ? " - " : "") + (c.firstname ? c.firstname + " " : "") + c.lastname : "";
}

export {
    getLongFormattedAddress,
    updateCustomerFormattedName, 
    noe
}